import logo from './logo.svg';
import './App.css';
import React from 'react';
import {Adsense} from '@ctrl/react-adsense';

function App() {
  return (
    <React.Fragment>
    <div className="App">
      <header className="App-header">
        {window.location.hostname === 'opentiss.uk' && (<Adsense
                    client="ca-pub-6337177885061052"
                    slot="1562485031"
                    style={{ display: 'inline-block', width: '468px', height: '60px' }}
                    format=""
                    />)}
        <p><img src={logo} className="App-logo" alt="logo" />
          Show with react and threejs.
        </p>
        <a
          className="App-link"
          title='Blogger of opentiss'
          href="http://blog.opentiss.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blogger of opentiss
        </a>
        <a
          className="App-link"
          title='Areditors'
          href="https://areditors.opentiss.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Areditors
        </a>
        <a
          className="App-link"
          title='Gomoku'
          href="https://game.opentiss.uk/gomoku"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gomoku
        </a>
      </header>
    </div>
    </React.Fragment>
  );
}

export default App;
